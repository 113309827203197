import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../platform-web3/config.service';
import { LoginService } from 'src/app/platform-web3/login.service';
import { DSAService } from 'src/app/dsutil-ng/dsa.service';
import { environment } from '../../../environments/environment';
import { Jsonx } from 'src/app/dsutil-ng/jsonx';
import { PassportAccessToken } from 'src/app/dsutil-ng/envelope';
import { AccessPoint } from 'src/app/dsutil-ng/access_point';
import { Connection } from 'src/app/dsutil-ng/connection';

const greening = environment.greening;

@Injectable({
  providedIn: 'root'
})
export class JoinRoleService {

  private privateBase = '';
  private dsnsHost = '';
  private contractGuest = 'auth.guest';
  private asTeacherService = 'Join.AsTeacher';
  private asStudentService = 'Join.AsStudent';
  private asParentService = 'Join.AsParent';

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private loginSrv: LoginService,
    private dsa: DSAService,
  ) {
    this.privateBase = this.config.API_PRIVATE_BASE;
    this.dsnsHost = this.config.DSNS_HOST;
  }

  /**加入成為訪客 */
  public async joinGuest(appName: string) {

    try {
      const accessToken = await this.loginSrv.getAccessToken().toPromise();

      try {
        return this.addGreeningApp(appName, accessToken);
      } catch (error) {
        return { info: 'error', errorCode: '002', msg: error };
      }
    } catch (error) {
      return { info: 'error', errorCode: '001', msg: error };
    }
  }

  /**以教師代碼加入 */
  public async joinTeacherByCode(appName: string, code: string) {
    try {
      const accessToken = await this.loginSrv.getAccessToken().toPromise();
      const rspTempToken = await this.getTempToken(appName).toPromise();
      const tempToken = (rspTempToken.info === 'success') ? rspTempToken.temp_token : '';
      if (tempToken) {
        try {
          const path = [
            `${this.dsnsHost}/${appName}/${this.contractGuest}/${this.asTeacherService}`,
            `?stt=PassportAccessToken`,
            `&AccessToken=${tempToken}`,
            `&rsptype=json`,
            `&content=${encodeURIComponent(`<Request><TeacherCode>${code}</TeacherCode></Request>`)}`
          ].join('');
          const rspNewRole: any = await this.http.get(path).toPromise();

          if (rspNewRole.Response && rspNewRole.Response.RefID) {
            const newId = rspNewRole.Response.RefID;
            try {
              const rspGreenging = await this.addGreeningApp(appName, accessToken);
              if (rspGreenging.info === 'success') {
                return { ...rspGreenging, newId };
              } else {
                return rspGreenging;
              }
            } catch (error) {
              return { info: 'error', errorCode: '005', msg: error };
            }
          } else {
            const errMsg = rspNewRole.Header.Status.Message;
            let tmp_msg: string;

            switch (rspNewRole.Header.Status.Code) {
              case 501:
                tmp_msg = '很抱歉，您無讀取資料權限！';
                break;
              case 504:
                if (errMsg.indexOf('教師代碼不正確或是已經被使用過了。') !== -1) {
                  tmp_msg = errMsg;
                } else if (errMsg.indexOf('此代碼已經設定過了。') !== -1) {
                  tmp_msg = errMsg;
                } else if (errMsg.indexOf('您的帳號已經聯結到「') !== -1) {
                  tmp_msg = errMsg;
                }
                break;
            }

            return { info: 'error', errorCode: (tmp_msg ? '567' : '004'), msg: tmp_msg || errMsg };
          }
        } catch (error) {
          return { info: 'error', errorCode: '003', msg: error };
        }
      } else {
        return { info: 'error', errorCode: '002', msg: 'no token' };
      }
    } catch (error) {
      return { info: 'error', errorCode: '001', msg: error };
    }
  }

  /**以學生代碼加入 */
  public async joinStudentByCode(appName: string, code: string) {
    try {
      const accessToken = await this.loginSrv.getAccessToken().toPromise();
      const rspTempToken = await this.getTempToken(appName).toPromise();
      const tempToken = (rspTempToken.info === 'success') ? rspTempToken.temp_token : '';
      if (tempToken) {
        try {
          const path = [
            `${this.dsnsHost}/${appName}/${this.contractGuest}/${this.asStudentService}`,
            `?stt=PassportAccessToken`,
            `&AccessToken=${tempToken}`,
            `&rsptype=json`,
            `&content=${encodeURIComponent(`<Request><StudentCode>${code}</StudentCode></Request>`)}`
          ].join('');
          const rspNewRole: any = await this.http.get(path).toPromise();

          if (rspNewRole.Response && rspNewRole.Response.RefID) {
            const newId = rspNewRole.Response.RefID;
            try {
              const rspGreenging = await this.addGreeningApp(appName, accessToken);
              if (rspGreenging.info === 'success') {
                return { ...rspGreenging, newId };
              } else {
                return rspGreenging;
              }
            } catch (error) {
              return { info: 'error', errorCode: '005', msg: error };
            }
          } else {
            const errMsg = rspNewRole.Header.Status.Message;
            let tmp_msg: string;

            switch (rspNewRole.Header.Status.Code) {
              case 501:
                tmp_msg = '很抱歉，您無讀取資料權限！';
                break;
              case 504:
                if (errMsg.indexOf('學生代碼不正確或是已經被使用過了。') !== -1) {
                  tmp_msg = errMsg;
                } else if (errMsg.indexOf('此代碼已經設定過了。') !== -1) {
                  tmp_msg = errMsg;
                } else if (errMsg.indexOf('您的帳號已經聯結到「') !== -1) {
                  tmp_msg = errMsg;
                }
                break;
            }

            return { info: 'error', errorCode: (tmp_msg ? '567' : '004'), msg: tmp_msg || errMsg };
          }
        } catch (error) {
          return { info: 'error', errorCode: '003', msg: error };
        }
      } else {
        return { info: 'error', errorCode: '002', msg: 'no token' };
      }
    } catch (error) {
      return { info: 'error', errorCode: '001', msg: error };
    }
  }

  /**以家長代碼加入 */
  public async joinParentByCode(appName: string, code: string, relationship: string) {
    try {
      const accessToken = await this.loginSrv.getAccessToken().toPromise();
      const rspTempToken = await this.getTempToken(appName).toPromise();
      const tempToken = (rspTempToken.info === 'success') ? rspTempToken.temp_token : '';
      if (tempToken) {
        try {
          const conn = await (new Connection(
            await AccessPoint.resolve(appName, this.contractGuest),
            new PassportAccessToken({ AccessToken: tempToken }),
          )).connect();

          const req = new Jsonx(`
            <Request>
              <ParentCode>${code}</ParentCode><Relationship>${relationship}</Relationship><RequestParentId>True</RequestParentId>
            </Request>
          `);

          const rsp = await conn.send('Join.AsParent', req);
          const pid = rsp.child('Success','ParentId').text;
          const sid = rsp.child('Success','StudentId').text;

          const newStudId = sid;
          try {
            const rspGreenging = await this.addGreeningApp(appName, accessToken);
            if (rspGreenging.info === 'success') {
              return { ...rspGreenging, newStudId };
            } else {
              return rspGreenging;
            }
          } catch (error) {
            console.log('加入小孩出現錯誤', error);
            return { info: 'error', errorCode: '005', msg: error };
          }

        } catch (error) {
          if (error.message && typeof error.message === 'string') {
            const errMsg = error.message;
            let tmp_msg: string;
            if (errMsg.indexOf('家長代碼不正確。') !== -1) {
              tmp_msg = errMsg;
            } else if (errMsg.indexOf('此代碼已經設定過了。') !== -1) {
              tmp_msg = errMsg;
            } else if (errMsg.indexOf('您的帳號已經聯結到「') !== -1) {
              tmp_msg = errMsg;
            }
            return { info: 'error', errorCode: (tmp_msg ? '567' : '004'), msg: tmp_msg || errMsg };
          } else {
            return { info: 'error', errorCode: '003', msg: error };
          }
        }
      } else {
        return { info: 'error', errorCode: '002', msg: 'no token' };
      }
    } catch (error) {
      return { info: 'error', errorCode: '001', msg: error };
    }
  }

  /** 將身份資料與 devapi 同步。 */
  public syncDevapi(appName: string) {
    return this.http.get(`${this.privateBase}/roleClaim/sync?dsns=${appName}`);
  }

  /** 新增學校連結。 */
  public async addSchool(dsns: string, title?: string) {
    const gree = await this.dsa.getConnection(greening, 'user');

    const dsreq = {
      Request: {
        Applications: {
          Application: {
            AccessPoint: dsns,
            Type: 'dynpkg',
            Title: title ?? dsns + ' 未提供名稱',
            Memo: '從 1campus.net 加入。'
          }
        }
      }
    };

    try {
      const dsrsp = await gree.send('AddApplicationRef', dsreq);
    } catch (err) {
      const msg: string = err?.message ?? '';
      if (msg.indexOf('account_app_ref_account_id_access_point_type_key') < 0) {
        throw err;
      }
    }
  }

  /** 移除學校連結。 */
  public async removeSchool(dsns: string) {
    const gree = await this.dsa.getConnection(greening, 'user');

    const dsreq = new Jsonx(`
    <Request>
        <Application>
          <AccessPoint>${dsns}</AccessPoint>
          <Type>dynpkg</Type>
        </Application>
    </Request>`);

    await gree.send('DeleteApplication', dsreq);
  }

  /** 測試在指定的學校是否有身份。 */
  public async testSchoolRole(dsns: string, role: Role) {

    const contract = new Map<Role, string>([
      ['teacher', 'auth.teacher'], ['student', 'auth.student'],
      ['parent', 'auth.parent'], ['admin', 'auth.school'],
      ['guest', 'auth.guest']
    ]);

    try {
      const atoken = await this.getTempToken(dsns).toPromise();
      const tempToken = (atoken.info === 'success') ? atoken.temp_token : '';
      const token = new PassportAccessToken({AccessToken: tempToken});
      const ap = await AccessPoint.resolve(dsns, contract.get(role));
      const conn  = new Connection(ap, token);
      await conn.connect();
      return true;
    } catch {
      return false;
    }
  }

  /**於 greening 加入關聯的學校清單 */
  private async addGreeningApp(appName: string, accessToken: string) {

    const content = encodeURIComponent([
      `<Request>`,
        `<Applications>`,
          `<Application>`,
            `<AccessPoint>${appName}</AccessPoint>`,
            `<Type>dynpkg</Type>`,
          `</Application>`,
        `</Applications>`,
      `</Request>`
    ].join(''));

    const url = [
      `${greening}/user/AddApplicationRef`,
      `?stt=passportaccesstoken`,
      `&AccessToken=${accessToken}`,
      `&content=${content}`,
      `&rsptype=json`
    ].join('');

    // console.log(url);

    const greeningRsp: any = await this.http.get(url).toPromise();

    if (greeningRsp.Header && greeningRsp.Header.Status && greeningRsp.Header.Status.Message) {
      if (greeningRsp.Header.Status.Message.indexOf('account_app_ref_account_id_access_point_type_key') !== -1) {
        return { info: 'success', errorCode: '200', msg: 'repeat' };
      } else {
        return { info: 'error', errorCode: '555', msg: 'ref_faild' };
      }
    } else {
      return { info: 'success', errorCode: '200', msg: '' };
    }
  }

  /**加入身分前，取得 DSA Token */
  private getTempToken(appName: string): Observable<any> {
    const url = `/auth/get_dsa_token`;
    return this.http.get(url).pipe(
      map(v => {
        return v;
      })
    );
  }

  /**取得家長安全機制狀態 */
  public async getAttemptStatus() {
    return this.http.get<{ failed_attempts?: number, lockout_until?: string | null }>('/auth/joinLockout/getLockoutInfo').toPromise();
  }

  /**設定家長安全機制狀態 */
  public async setFailedStatus() {
    return this.http.put<void>('/auth/joinLockout/incrementFailedAttempts', {}).toPromise();
  }

  public async resetFailedStatus() {
    return this.http.put<void>('/auth/joinLockout/resetFailedAttempts', {}).toPromise();
  }

  public async dbtimestamp() {
    return this.http.get<{db_timestamp: string}>('/service/common/dbtimestamp').toPromise();
  }
}
