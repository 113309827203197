import { ApplicationRef, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Observable, of } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { OneCampusSchoolRecord, SchoolListService } from '../../../platform-web3/school-list.service';
import { RoleFormatPipe } from '../../pipes/role-format.pipe';
import { JoinRoleService } from '../join-role.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-join-role',
  templateUrl: './join-role.component.html',
  styleUrls: ['./join-role.component.css']
})
export class JoinRoleComponent implements OnInit, OnDestroy {

  schoolControl = new UntypedFormControl(null, [Validators.required]);

  // roleFormGroup: FormGroup = new FormGroup({
  //   roleCtrl: new FormControl(null, Validators.required),
  // });
  // get roleCtrl() { return this.roleFormGroup.get('roleCtrl'); }

  filteredOptions: Observable<OneCampusSchoolRecord[]>;
  schoolList: OneCampusSchoolRecord[] = [];
  // 代表角色是否已經在。
  targetRoleExists = new Map<Role, boolean>();
  targetRole: Role;
  targetSchool: OneCampusSchoolRecord;
  targetCode = '';
  targetRelation = '';
  joinFinish = false;
  message = '';
  nextUrl = '';
  isSaving = false;

  attemptStatus: { failed_attempts?: number; lockout_until?: string; } = {};
  lockState: 'LOCK' | 'UNLOCK' | 'FAILED' | 'UNKNOW' = 'UNKNOW';
  timer: any;
  remainingTime: string;

  constructor(
    private schoolListSrv: SchoolListService,
    private joinRoleSrv: JoinRoleService,
    private appRef: ApplicationRef,
    private change: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private rolePipe: RoleFormatPipe,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
  ) { }

  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

  ngOnInit() {
    const dsns = this.data?.assignedSchoolDSNS;

    if (dsns) {
      const school = this.schoolListSrv.searchSchool(this.data?.assignedSchoolDSNS)
        .subscribe(school => {
          const found = school.find(v => v.DSNS === dsns);
          if (found) {
            this.schoolControl.setValue(found, {onlySelf: true});
            this.selectedSchool(found);
          }
        });
    };

    this.filteredOptions = this.schoolControl.valueChanges.pipe(
      tap(v => this.targetSchool = null),
      startWith(''),
      debounceTime(300),       // wait 300ms after each keystroke before considering the term
      distinctUntilChanged(),   // ignore if next search term is same as previous
      filter(v => (v || '').length),
      map(v => v.trim()),
      switchMap((term) => {
        if (term) {
          return this.schoolListSrv.searchSchool(term);
        } else {
          return of([] as OneCampusSchoolRecord[]);
        }
      }),
      tap(v => this.schoolList = v)
    );
  }

  ngOnDestroy(): void {
    if (this.timer) { clearInterval(this.timer); }
  }

  /**
   * 學校自動完成選擇後，在輸入框要呈現的值
   * @param school 自動完成選中的項目
   */
  displayFn(school: OneCampusSchoolRecord): string {
    return (school) ? `${school.SchoolName}` : '';
  }

  selectedSchool(school: OneCampusSchoolRecord) {
    this.targetSchool = school;
    this.message = '';
  }

  async checkSchool(stepper: MatStepper) {

    this.message = '';

    if (!!!this.schoolControl.value && !!!this.targetSchool) {
      this.message = this.i18nextSrv.t('尚未選擇學校').toString();
    } else {
      if (!!!this.targetSchool) {
        const targetSchool = this.schoolList.find(s => s.SchoolName === this.schoolControl.value);
        if (targetSchool) {
          this.targetSchool = targetSchool;
        } else {
          this.message = this.i18nextSrv.t('查無此校！此校尚未加入1Campus智慧校園服務').toString();
        }
      }
    }
    if (!!this.targetSchool) { stepper.next(); }
  }

  async selectedRole(role: Role, stepper: MatStepper) {
    this.targetRole = role;
    this.targetCode = '';
    this.targetRelation = '';
    this.message = '';

    const exists = await this.joinRoleSrv.testSchoolRole(this.targetSchool.DSNS, this.targetRole);
    this.targetRoleExists.set(this.targetRole, exists);

    if (role === 'parent') {
      await this.checkAttemptStatus();
    } else {
      if (this.timer) { clearInterval(this.timer); }
    }

    this.appRef.tick();
    stepper.next();
  }

  async checkAttemptStatus() {
    try {
      if (this.timer) { clearInterval(this.timer); }

      this.attemptStatus = await this.joinRoleSrv.getAttemptStatus();
      const lockoutTime = dayjs(this.attemptStatus.lockout_until);
      const currTime = await this.joinRoleSrv.dbtimestamp();
      let diffInSeconds = lockoutTime.diff(dayjs(currTime.db_timestamp), 'second');

      if (this.attemptStatus.failed_attempts >= 3 && diffInSeconds > 0) {
        this.timer = setInterval(async () => {
          diffInSeconds --;
          if (diffInSeconds <= 0) {
            if (this.timer) {
              clearInterval(this.timer);
              await this.checkAttemptStatus();
            }
          } else {
            this.lockState = 'LOCK';

            const minutes = Math.floor(diffInSeconds / 60);
            const seconds = diffInSeconds % 60;
            this.remainingTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          }
        }, 1000);

        // console.log('Account is locked until', this.attemptStatus.lockout_until);
      } else {
        // console.log('currTime', currTime);
        // console.log('lockoutTime', lockoutTime);
        // console.log('diffInSeconds', diffInSeconds);
        this.lockState = 'UNLOCK';
      }
    } catch (error) {
      this.lockState = 'FAILED';
    }
  }

  async joinNewRole(stepper: MatStepper) {
    if (this.isSaving) { return; }
    if (this.targetRole === 'parent' && this.lockState !== 'UNLOCK') { return; }

    this.message = '';

    try {
      this.isSaving = true;

      let rsp: any;

      switch (this.targetRole) {
        case 'guest':
          rsp = await this.joinRoleSrv.joinGuest(this.targetSchool.DSNS);
          break;
        case 'teacher':
          if (this.targetRoleExists.get(this.targetRole)) {
            rsp = await this.addSchoolLink();
            break;
          }

          if (this.targetCode) {
            rsp = await this.joinRoleSrv.joinTeacherByCode(this.targetSchool.DSNS, this.targetCode);
          } else {
            rsp = { errorCode: '567', msg: '需輸入代碼' };
          }
          break;
        case 'student':
          if (this.targetRoleExists.get(this.targetRole)) {
            rsp = rsp = await this.addSchoolLink();
            break;
          }

          if (this.targetCode) {
            rsp = await this.joinRoleSrv.joinStudentByCode(this.targetSchool.DSNS, this.targetCode);
          } else {
            rsp = { errorCode: '567', msg: '需輸入代碼' };
          }
          break;
        case 'parent':
          if (this.targetCode && this.targetRelation) {
            rsp = await this.joinRoleSrv.joinParentByCode(this.targetSchool.DSNS, this.targetCode, this.targetRelation);
          } else {
            rsp = { errorCode: '567', msg: '需輸入代碼及親子關係' };
          }
          break;
      }

      if (rsp.info === 'success') {
        this.message = this.i18nextSrv.t('加入「v1」成為 v2 成功！', {
          v1: this.targetSchool.SchoolName,
          v2: this.rolePipe.transform(this.targetRole)
        }).toString();


        await this.joinRoleSrv.syncDevapi(this.targetSchool.DSNS).toPromise();

        // 重新導向到 auth 重新取得 token，並返回首頁
        this.nextUrl = `/auth/redirect_ischool_oauth?next=/s/${this.targetSchool.DSNS}`;
        if (this.targetRole != 'guest') {
          const newId = rsp.newId;
          if (newId) {
            this.nextUrl = `/auth/redirect_ischool_oauth?next=/s/${this.targetSchool.DSNS}/r/${this.targetRole}/${newId}/g`;
          }
        }
        if (this.targetRole = 'parent') {
          await this.joinRoleSrv.resetFailedStatus();
        }
        this.joinFinish = true;
        this.appRef.tick();
        stepper.next();
      } else {
        this.message = this.i18nextSrv.t('加入「v1」成為 v2 失敗！', {
          v1: this.targetSchool.SchoolName,
          v2: this.rolePipe.transform(this.targetRole)
        }).toString();

        let tmp_msg = rsp.msg;

        try {
          if (this.targetRole === 'parent' && ((tmp_msg || '').indexOf('不正確') !== -1)) {
            this.lockState = 'LOCK';
            await this.joinRoleSrv.setFailedStatus();
            await this.checkAttemptStatus();
          }
        } catch (error) {
          console.log(error);
        }

        if (rsp.errorCode === '567') {
          tmp_msg = this.i18nextSrv.t(rsp.msg).toString();
        }

        this.message += ' ' + tmp_msg;
      }
    } catch (error) {
      this.message = this.i18nextSrv.t('加入「v1」成為 v2 發生錯誤！', {
        v1: this.targetSchool.SchoolName,
        v2: this.rolePipe.transform(this.targetRole)
      }).toString();
      console.log(error);
    } finally {
      this.isSaving = false;
    }
  }

  private async addSchoolLink() {
    if (this.targetRoleExists.get(this.targetRole)) {
      const { DSNS, SchoolName } = this.targetSchool;
      try {
        await this.joinRoleSrv.addSchool(DSNS, SchoolName);
        return { info: 'success' };
      } catch(err) {
        return { errorCode: '568', msg: this.i18nextSrv.t('加入學校連結失敗。').toString() };
      }
    }
  }

  resetStepper(stepper: MatStepper) {
    this.targetRoleExists = new Map<Role, boolean>();
    this.targetSchool = null;
    this.targetRole = null;
    this.targetCode = null;
    this.targetRelation = '';
    this.joinFinish = false;
    this.message = '';
    this.schoolControl.setValue(null);
    stepper.reset();
  }

  reLogin() {
    window.location.href = this.nextUrl;
  }
}
