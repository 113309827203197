import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { setTheme } from 'ngx-bootstrap/utils';
import { PlatformUrlService } from './platform-web3/platform-url.service';
import { AuthInfo, UserState } from './platform-web3/user.state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  mobile_mode = false;
  authInfo: AuthInfo = {} as AuthInfo;
  userLang = '';

  @ViewChild('tplAD') tplAD: TemplateRef<any>;
  unSubscribe$ = new Subject<void>();

  constructor(
    platformUrl: PlatformUrlService,
    private store: Store,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
    private dialog: MatDialog,
    ) {

    if (platformUrl.mobileMode) {
      this.mobile_mode = true;
    }

    setTheme('bs4');
  }

  async ngOnInit() {
    this.authInfo = await this.store.selectOnce(UserState.authInfo).toPromise();
    let userLang = 'zh-TW';

    switch (this.authInfo?.language?.toLowerCase()) {
      case 'zh-hans-cn':
      case 'chinesesimplified':
        userLang = 'zh-CN';
        break;
      case 'en-us':
      case 'english':
        userLang = 'en';
        break;
    }
    await this.i18nextSrv.changeLanguage(userLang);

    this.store.select(UserState.authInfo).pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe(authInfo => {
      if (authInfo.account) {
        const lastClosedTime = localStorage.getItem('eventAd');
        if (!lastClosedTime || (Date.now() - new Date(lastClosedTime).getTime()) > 7 * 24 * 60 * 60 * 1000) {
          this.showAD();
        }
      }
    });

  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  showAD() {
    console.log('showAD');
    const dialogRef = this.dialog.open(this.tplAD, {
      disableClose: true,
      panelClass: 'my-mat-dialog-content'
    });
    dialogRef.beforeClosed().subscribe(() => {
      localStorage.setItem('eventAd', new Date().toISOString());
    });
  }
}

